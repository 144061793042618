<template>
    <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_740_740)">
        <path d="M410 513H102C74.9577 512.968 49.032 502.212 29.9102 483.09C10.7884 463.968 0.0317585 438.042 0 411L0 103C0.0317585 75.9577 10.7884 50.032 29.9102 30.9102C49.032 11.7884 74.9577 1.03176 102 1H410C437.042 1.03176 462.968 11.7884 482.09 30.9102C501.212 50.032 511.968 75.9577 512 103V411C511.968 438.042 501.212 463.968 482.09 483.09C462.968 502.212 437.042 512.968 410 513ZM102 41C85.5623 41.0185 69.8031 47.5566 58.1798 59.1798C46.5566 70.8031 40.0185 86.5623 40 103V411C40.0185 427.438 46.5566 443.197 58.1798 454.82C69.8031 466.443 85.5623 472.981 102 473H410C426.438 472.981 442.197 466.443 453.82 454.82C465.443 443.197 471.981 427.438 472 411V103C471.981 86.5623 465.443 70.8031 453.82 59.1798C442.197 47.5566 426.438 41.0185 410 41H102Z" fill="white"/>
        <path d="M256 325.36C250.696 325.36 245.609 323.253 241.858 319.502C238.107 315.751 236 310.664 236 305.36V119.44C236 114.136 238.107 109.049 241.858 105.298C245.609 101.547 250.696 99.4399 256 99.4399C261.304 99.4399 266.391 101.547 270.142 105.298C273.893 109.049 276 114.136 276 119.44V305.36C276 310.664 273.893 315.751 270.142 319.502C266.391 323.253 261.304 325.36 256 325.36Z" fill="white"/>
        <path d="M256 339.62C251.56 339.633 247.162 338.766 243.06 337.069C238.957 335.371 235.232 332.877 232.1 329.73L154.54 252.18C150.788 248.429 148.681 243.34 148.681 238.035C148.681 232.73 150.788 227.642 154.54 223.89C158.291 220.139 163.38 218.031 168.685 218.031C173.99 218.031 179.078 220.139 182.83 223.89L256 297.07L329.17 223.89C331.028 222.033 333.233 220.559 335.66 219.554C338.087 218.548 340.688 218.031 343.315 218.031C345.942 218.031 348.543 218.548 350.97 219.554C353.397 220.559 355.602 222.033 357.46 223.89C359.317 225.748 360.791 227.953 361.796 230.38C362.802 232.807 363.319 235.408 363.319 238.035C363.319 240.662 362.802 243.263 361.796 245.69C360.791 248.117 359.317 250.323 357.46 252.18L279.9 329.73C276.768 332.877 273.043 335.371 268.94 337.069C264.838 338.766 260.44 339.633 256 339.62Z" fill="white"/>
        <path d="M343.32 412.56H168.68C163.376 412.56 158.289 410.453 154.538 406.702C150.787 402.951 148.68 397.864 148.68 392.56C148.68 387.256 150.787 382.169 154.538 378.418C158.289 374.667 163.376 372.56 168.68 372.56H343.32C348.624 372.56 353.711 374.667 357.462 378.418C361.213 382.169 363.32 387.256 363.32 392.56C363.32 397.864 361.213 402.951 357.462 406.702C353.711 410.453 348.624 412.56 343.32 412.56Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_740_740">
        <rect width="512" height="512" fill="white"/>
        </clipPath>
        </defs>
    </svg>
  </template>
  
  <script>
  export default {
    name: 'DownloadIcon'
  }
  </script>
  