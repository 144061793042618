<template>
    <div class="tooltip" 
         @mouseover="showTooltip = true" 
         @mousemove="updateTooltipPosition" 
         @mouseout="showTooltip = false"
    >
      <slot></slot>
      <div class="tooltip-content" 
           v-if="showTooltip" 
           :style="{ left: `${x}px`, top: `${y}px` }"
      >
        {{content}}
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from "vue";
  export default {
    name: "TooltipHelper",
    props: ["content"],
    setup() {
      const showTooltip = ref(false);
      const x = ref(0);
      const y = ref(0);
  
      const updateTooltipPosition = (event) => {
        x.value = event.clientX + 10;
        y.value = event.clientY + 10;
      };
  
      return { showTooltip, x, y, updateTooltipPosition };
    },
  };
  </script>
  
  <style scoped>
  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-content {
    max-width: 200px; 
    visibility: hidden;
    border: 1px solid var(--secondary-color);
    background-color: var(--background-color);
    color: var(--alt-color);
    text-align: center;
    border-radius: 6px;
    padding: 7px;
    position: fixed;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
  }
  
  .tooltip:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
  }
  </style>
  