<template>
  <div class="main-header-right">
    <div class="toggle-theme" @click="toggleTheme"><toggle-theme-icon /></div>
    <div v-if="account">
      <TooltipHelper :content="`You have ${tokensAmount} dyno passes`">
        <div class="dyno-pass animate">
          Dyno<br>Pass
          <span>{{ tokensAmount }}x</span>
        </div>
      </TooltipHelper>
    </div>
    <button class="btn-connect" @click="connect">
      {{ account ? '0x...' + account.substring(account.length - 4) : 'Connect' }}
    </button>
  </div>
</template>

<script>
import Web3 from 'web3';
import ContractABI from '../ContractABI.json';
import TooltipHelper from './TooltipHelper.vue';
import ToggleThemeIcon from './ToggleThemeIcon.vue';

export default {
  components: {
    TooltipHelper,
    ToggleThemeIcon
  },
  data() {
    return {
      account: '',
      tokensAmount: null,
      ownerId: null,
    };
  },
  methods: {
    async connect() {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        try {
          await window.ethereum.enable();
          const accounts = await window.web3.eth.getAccounts();
          this.account = accounts[0];
          this.$emit('updateAccount', this.account); // emit an event with the connected account
          this.fetchTokens();
          this.fetchTokenIds();
        } catch (err) {
          console.error("User rejected connection:", err);
          // You can handle the error in a more user-friendly way, 
          // for example, showing a notification or changing some state in your UI.
        }
      } else {
        alert('Non-Ethereum browser detected. You should consider trying Rabby, XDFI, Phantom, or similar.');
      }
    },

    async fetchTokens() {
      const contract = new window.web3.eth.Contract(ContractABI, '0xdE8E357bF9EA57CE187755155195d1B6616bE015');
      const balance = await contract.methods.balanceOf(this.account).call();
      this.tokensAmount = balance;
    },
    async fetchTokenIds() {
      const contract = new window.web3.eth.Contract(ContractABI, '0xdE8E357bF9EA57CE187755155195d1B6616bE015');
      const tokenIds = await contract.methods.getTokenIdsOfUserAddress(this.account).call();
      console.log("Token IDs: ", tokenIds);
    },
    async fetchOwner(tokenId) {
      if (!window.web3) {
        // Web3 hasn't been initialized yet, so we can't proceed.
        console.error('Web3 is not initialized. Connect to the wallet first.');
        return;
      }
      const contract = new window.web3.eth.Contract(ContractABI, '0xdE8E357bF9EA57CE187755155195d1B6616bE015');
      const ownerId = await contract.methods.ownerOf(tokenId).call();
      this.$emit('updateOwnerId', ownerId);
    },
    resetState() {
      // Define your reset state logic here. As an example, it may look something like:
      this.account = '';
      this.tokensAmount = null;
      this.$emit('walletDisconnected');
      // Don't forget to reset any other state that might be dependent on the wallet connection.
    },
    toggleTheme() {
      const root = document.documentElement;
      const darkMode = getComputedStyle(root).getPropertyValue('--primary-color').trim() === getComputedStyle(root).getPropertyValue('--primary-color-dark').trim();
      root.style.setProperty('--primary-color', `var(--primary-color-${darkMode ? 'light' : 'dark'})`);
      root.style.setProperty('--secondary-color', `var(--secondary-color-${darkMode ? 'light' : 'dark'})`);
      root.style.setProperty('--alt-color', `var(--alt-color-${darkMode ? 'light' : 'dark'})`);
      root.style.setProperty('--background-color', `var(--background-color-${darkMode ? 'light' : 'dark'})`);
      root.style.setProperty('--background-image', `var(--background-image-${darkMode ? 'light' : 'dark'})`);
    },
  },
  mounted() {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', () => {
        this.resetState();
      });
      window.ethereum.on('chainChanged', () => {
        // Optionally, you could handle network changes as well.
      });
      window.ethereum.on('disconnect', () => {
        this.resetState();
      });
    }
  },
  beforeUnmount() {
    if (window.ethereum) {
      window.ethereum.removeAllListeners('accountsChanged');
      window.ethereum.removeAllListeners('chainChanged');
      window.ethereum.removeAllListeners('disconnect');
    }
  }
};
</script>

<style lang="scss" scoped src="../assets/scss/main-header-right.scss"></style>
