<template>
    <div class="main-footer">
      <main-footer-left></main-footer-left>
      <main-footer-right></main-footer-right>
    </div>
  </template>
  
  <script>
  import MainFooterLeft from './MainFooterLeft.vue'
  import MainFooterRight from './MainFooterRight.vue'
  
  export default {
    components: {
      MainFooterLeft,
      MainFooterRight
    }
  }
  </script>
  
  <style lang="scss" scoped src="../assets/scss/main-footer.scss"></style>
  