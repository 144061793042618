<template>
  <div id="main-content">
    <ImageGrid 
      :gridSize="gridSize" 
      :searchTerm="searchTerm" 
      :dynos="dynos" 
      :ownerId="ownerId" 
      @updateSearchTerm="$emit('updateSearchTerm', $event)" 
      @requestOwner="$emit('requestOwner', $event)" 
    />
  </div>
</template>

<script>
import ImageGrid from './ImageGrid.vue';

export default {
  name: 'MainContent',
  components: {
    ImageGrid,
  },
  props: {
    searchTerm: {
      type: String,
      default: ''
    },
    gridSize: {
      type: String,
      default: 'lg-grid'
    },
    dynos: {
      type: Array,
      default: () => []
    },
    ownerId: {
      type: String,
      default: ''
    },
  },
};
</script>

<style lang="scss" scoped src="../assets/scss/main-content.scss"></style>