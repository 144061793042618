<template>
    <div class="main-header-left">
        <div class="logo">
            <logo-dyno />
        </div>
        <span class="text">120 digital dinosaur collages <br> evolving on Ethereum <!--<a @click="toggleModal">MORE</a>--></span>
    </div>
    <ModalAbout v-if="showModal" @close="toggleModal"></ModalAbout>
  </template>
  
  <script>
  import LogoDyno from './LogoDyno.vue';
  import ModalAbout from './ModalAbout.vue'

  export default {
    name: 'MainHeaderLeft',
    components: {
        LogoDyno,
        ModalAbout
    },
    data() {
        return {
        showModal: false
        }
    },
    methods: {
        toggleModal() {
        this.showModal = !this.showModal
        }
    }
  }
  </script>
  
  <style lang="scss" scoped src="../assets/scss/main-header-left.scss"></style>
  