import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import VueLazyload from 'vue3-lazyload';
import 'normalize.css/normalize.css'
import './assets/scss/app.scss'
import errorImage from './assets/img/error.png'
import loadingImage from './assets/img/placeholder.png'

createApp(App)
  .use(store)
  .use(router)
  .use(VueLazyload, {
    preLoad: 1,
    error: errorImage,  
    loading: loadingImage,
    attempt: 1,
  })
  .mount('#app')
