<template>
  <div class="search-bar-right">
    <button
      class="toggle-btn"
      @click="toggleGridSize('lg-grid')"
      :class="{ 'is-active': gridSize === 'lg-grid' }"
    >
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.32382 0H1.82629C1.07317 0 0.460449 0.612719 0.460449 1.36584V5.86338C0.460449 6.6165 1.07317 7.22922 1.82629 7.22922H6.32382C7.07695 7.22922 7.68967 6.6165 7.68967 5.86338V1.36584C7.68967 0.612719 7.07695 0 6.32382 0Z" fill="#808080"/>
        <path d="M15.0947 0H10.5972C9.84404 0 9.23132 0.612719 9.23132 1.36584V5.86338C9.23132 6.6165 9.84404 7.22922 10.5972 7.22922H15.0947C15.8478 7.22922 16.4605 6.6165 16.4605 5.86338V1.36584C16.4605 0.612719 15.8478 0 15.0947 0Z" fill="#808080"/>
        <path d="M6.32382 8.77078H1.82629C1.07317 8.77078 0.460449 9.3835 0.460449 10.1366V14.6342C0.460449 15.3873 1.07317 16 1.82629 16H6.32382C7.07695 16 7.68967 15.3873 7.68967 14.6342V10.1366C7.68967 9.3835 7.07695 8.77078 6.32382 8.77078Z" fill="#808080"/>
        <path d="M15.0947 8.77078H10.5972C9.84404 8.77078 9.23132 9.3835 9.23132 10.1366V14.6342C9.23132 15.3873 9.84404 16 10.5972 16H15.0947C15.8478 16 16.4605 15.3873 16.4605 14.6342V10.1366C16.4605 9.3835 15.8478 8.77078 15.0947 8.77078Z" fill="#808080"/>
      </svg>
    </button>
    <button
      class="toggle-btn"
      @click="toggleGridSize('sm-grid')"
      :class="{ 'is-active': gridSize === 'sm-grid' }"
    >
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.85199 0.497306C4.85199 0.22213 4.62901 0 4.35277 0H0.991401C0.71517 0 0.492188 0.22213 0.492188 0.497306V3.84749C0.492188 4.12267 0.71517 4.3448 0.991401 4.3448H4.35444C4.63067 4.3448 4.85365 4.12267 4.85365 3.84749V0.497306H4.85199Z" fill="#808080"/>
        <path d="M10.6711 0.497306C10.6711 0.22213 10.4481 0 10.1719 0H6.81049C6.53426 0 6.31128 0.22213 6.31128 0.497306V3.84749C6.31128 4.12267 6.53426 4.3448 6.81049 4.3448H10.1719C10.4481 4.3448 10.6711 4.12267 10.6711 3.84749V0.497306Z" fill="#808080"/>
        <path d="M16.4904 0.497306C16.4904 0.22213 16.2674 0 15.9912 0H12.6298C12.3536 0 12.1306 0.22213 12.1306 0.497306V3.84749C12.1306 4.12267 12.3536 4.3448 12.6298 4.3448H15.9929C16.2691 4.3448 16.4921 4.12267 16.4921 3.84749V0.497306H16.4904Z" fill="#808080"/>
        <path d="M4.85199 6.32573C4.85199 6.05055 4.62901 5.82842 4.35277 5.82842H0.991401C0.71517 5.82842 0.492188 6.05055 0.492188 6.32573V9.67426C0.492188 9.94943 0.71517 10.1716 0.991401 10.1716H4.35444C4.63067 10.1716 4.85365 9.94943 4.85365 9.67426V6.32573H4.85199Z" fill="#808080"/>
        <path d="M10.6711 6.32573C10.6711 6.05055 10.4481 5.82842 10.1719 5.82842H6.81049C6.53426 5.82842 6.31128 6.05055 6.31128 6.32573V9.67426C6.31128 9.94943 6.53426 10.1716 6.81049 10.1716H10.1719C10.4481 10.1716 10.6711 9.94943 10.6711 9.67426V6.32573Z" fill="#808080"/>
        <path d="M16.4904 6.32573C16.4904 6.05055 16.2674 5.82842 15.9912 5.82842H12.6298C12.3536 5.82842 12.1306 6.05055 12.1306 6.32573V9.67426C12.1306 9.94943 12.3536 10.1716 12.6298 10.1716H15.9929C16.2691 10.1716 16.4921 9.94943 16.4921 9.67426V6.32573H16.4904Z" fill="#808080"/>
        <path d="M4.35277 11.6552H0.991401C0.71517 11.6552 0.492188 11.8773 0.492188 12.1525V15.5027C0.492188 15.7779 0.71517 16 0.991401 16H4.35444C4.63067 16 4.85365 15.7779 4.85365 15.5027V12.1525C4.85199 11.879 4.62901 11.6552 4.35277 11.6552Z" fill="#808080"/>
        <path d="M10.1719 11.6552H6.81049C6.53426 11.6552 6.31128 11.8773 6.31128 12.1525V15.5027C6.31128 15.7779 6.53426 16 6.81049 16H10.1719C10.4481 16 10.6711 15.7779 10.6711 15.5027V12.1525C10.6711 11.879 10.4481 11.6552 10.1719 11.6552Z" fill="#808080"/>
        <path d="M15.9912 11.6552H12.6298C12.3536 11.6552 12.1306 11.8773 12.1306 12.1525V15.5027C12.1306 15.7779 12.3536 16 12.6298 16H15.9929C16.2691 16 16.4921 15.7779 16.4921 15.5027V12.1525C16.4904 11.879 16.2674 11.6552 15.9912 11.6552Z" fill="#808080"/>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SearchBarRight',
  data() {
    return {
      gridSize: 'lg-grid', // initial state
    };
  },
  methods: {
    toggleGridSize(size) {
      this.gridSize = size;
      this.$emit('toggle-grid-size', size);
    },
  },
};
</script>

<style lang="scss" scoped src="../assets/scss/search-bar-right.scss"></style>
