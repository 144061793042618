<template>
  <div class="search-bar-left" @resetSearchBar="resetLocalState">
    <TooltipHelper content="Advanced search coming soon">
      <button class="filter-btn">
        <FilterIcon />
      </button>
    </TooltipHelper>
    <div class="search-field-wrapper">
      <input v-model="localSearchTerm" type="text" class="search-field" placeholder="Dyno #">
      <CloseIcon v-if="localSearchTerm" @click="clearSearch" class="clear-icon" />
    </div>

    <div v-if="account">
      <TooltipHelper content="Show your dynos only">
        <label class="animate">
          <input type="checkbox" v-model="myDynosChecked" @change="toggleMyDynos" />My dynos
        </label>
      </TooltipHelper>
    </div>

  </div>
</template>

<script>
import CloseIcon from './CloseIcon.vue';
import FilterIcon from './FilterIcon.vue';
import ContractABI from '../ContractABI.json';
import TooltipHelper from './TooltipHelper.vue';

export default {
  name: 'SearchBarLeft',
  components: {
    CloseIcon,
    FilterIcon,
    TooltipHelper,
  },
  props: {
    searchTerm: {
      type: String,
      default: ''
    },
    account: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localSearchTerm: this.searchTerm,
      myDynosChecked: false
    }
  },
  watch: {
    searchTerm(newVal) {
      this.localSearchTerm = newVal;
    },
    localSearchTerm(newVal) {
      this.$emit('updateSearchTerm', newVal);
    },
    account(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.resetLocalState();
      }
    }
  },
  methods: {
    clearSearch() {
      this.localSearchTerm = '';
    },
    async toggleMyDynos() {
      //console.log('toggleMyDynos called');
      if (!this.myDynosChecked) {
        this.$emit('updateDynos', null);
        return;
      }
      const contract = new window.web3.eth.Contract(ContractABI, '0xdE8E357bF9EA57CE187755155195d1B6616bE015');
      const tokenIds = await contract.methods.getTokenIdsOfUserAddress(this.account).call();
      const tokenIdsAsString = tokenIds.map(id => id.toString());
      this.$emit('updateDynos', tokenIdsAsString);
      console.log('updateDynos event emitted');
    },
    resetApplicationState() {
      this.$emit('resetState');
    },
    resetLocalState() {
      this.localSearchTerm = '';
      this.myDynosChecked = false;
      this.$emit('updateSearchTerm', this.localSearchTerm);
      this.$emit('updateDynos', null);
    }
  }
}
</script>

<style lang="scss" scoped src="../assets/scss/search-bar-left.scss"></style>
