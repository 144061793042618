<template>
    <svg class="icon" width="33" height="26" viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M27.7953 2.27145C25.6395 1.27925 23.3636 0.575352 21.0258 0.177734C20.7059 0.752631 20.4165 1.34412 20.1587 1.94974C17.6685 1.57251 15.1361 1.57251 12.6459 1.94974C12.388 1.34419 12.0986 0.752701 11.7788 0.177734C9.43952 0.57871 7.16215 1.28428 5.00417 2.27663C0.720021 8.64857 -0.441343 14.8623 0.139339 20.9877C2.64824 22.8512 5.45642 24.2684 8.44181 25.1777C9.11403 24.2688 9.70886 23.3046 10.22 22.2953C9.24917 21.9308 8.31216 21.4811 7.41981 20.9514C7.65466 20.7802 7.88435 20.6037 8.1063 20.4325C10.7029 21.66 13.5368 22.2965 16.4062 22.2965C19.2755 22.2965 22.1095 21.66 24.7061 20.4325C24.9306 20.6167 25.1603 20.7931 25.3926 20.9514C24.4985 21.482 23.5598 21.9325 22.5872 22.2979C23.0977 23.3068 23.6926 24.2702 24.3654 25.1777C27.3533 24.2721 30.1637 22.8555 32.673 20.9903V20.9903C33.3544 13.8867 31.5091 7.73014 27.7953 2.27145ZM10.9555 17.2206C9.33735 17.2206 8.00049 15.7444 8.00049 13.9283C8.00049 12.1121 9.2909 10.6229 10.9504 10.6229C12.6098 10.6229 13.9364 12.1121 13.908 13.9283C13.8796 15.7444 12.6047 17.2206 10.9555 17.2206ZM21.8569 17.2206C20.2361 17.2206 18.9044 15.7444 18.9044 13.9283C18.9044 12.1121 20.1948 10.6229 21.8569 10.6229C23.5189 10.6229 24.8351 12.1121 24.8067 13.9283C24.7783 15.7444 23.506 17.2206 21.8569 17.2206Z"
            fill="#808080" />
    </svg>
</template>

<script>
export default {
    name: "LogoDiscord",
};
</script>
