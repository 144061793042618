<template>
    <svg class="icon" width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M16.3107 14.7839C13.6206 14.7839 11.4379 12.6033 11.4379 9.91097C11.4379 7.21866 13.6206 5.03809 16.3107 5.03809C19.0009 5.03809 21.1836 7.21866 21.1836 9.91097C21.1836 12.6033 19.0009 14.7839 16.3107 14.7839ZM14.1921 9.91097C14.1921 11.0815 15.1411 12.0296 16.3107 12.0296C17.4804 12.0296 18.4294 11.0815 18.4294 9.91097C18.4294 8.7404 17.4804 7.79232 16.3107 7.79232C15.1411 7.79232 14.1921 8.7404 14.1921 9.91097Z"
            fill="#808080" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.632812 9.91734L9.95485 0.588867H22.6667L31.9887 9.91734L16.3108 25.5889L0.632812 9.91734ZM23.5142 6.52103C19.5533 2.54277 13.0683 2.54279 9.10739 6.52105L5.71756 9.91092L9.10739 13.3008C13.0683 17.279 19.5533 17.279 23.5142 13.3008L26.904 9.91092L23.5142 6.52103Z"
            fill="#808080" />
    </svg>
</template>

<script>
export default {
    name: "LogoLooksRare",
};
</script>
