<template>
    <div class="main-footer-left">
      <span>Generator v0.75&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Website v3.0.0</span>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MainFooterLeft'
  }
  </script>
  
  <style lang="scss" scoped src="../assets/scss//main-footer-left.scss"></style>
  