<template>
  <div class="main-footer-right">
    <!-- Discord -->
    <a href="https://discord.com/" target="_blank" rel="noopener noreferrer">
      <logo-discord></logo-discord>
    </a>
    <!-- Twitter -->
    <a href="https://twitter.com/dynofriends" target="_blank" rel="noopener noreferrer">
      <logo-twitter></logo-twitter>
    </a>
    <!-- Opensea -->
    <a href="https://opensea.io/collection/dynofriends" target="_blank" rel="noopener noreferrer">
      <logo-open-sea></logo-open-sea>
    </a>
    <!-- LooksRare-->
    <a href="https://looksrare.org/collections/0xdE8E357bF9EA57CE187755155195d1B6616bE015" target="_blank" rel="noopener noreferrer">
      <logo-looks-rare></logo-looks-rare>
    </a>
    <!-- Gem -->
    <a href="https://pro.opensea.io/collection/dynofriends" target="_blank" rel="noopener noreferrer">
      <logo-gem></logo-gem>
    </a>
  </div>
</template>
  
<script>
import LogoDiscord from './LogoDiscord.vue'
import LogoTwitter from './LogoTwitter.vue'
import LogoOpenSea from './LogoOpenSea.vue'
import LogoLooksRare from './LogoLooksRare.vue'
import LogoGem from './LogoGem.vue'

export default {
  name: 'MainFooterRight',
  components: {
    LogoDiscord,
    LogoTwitter,
    LogoOpenSea,
    LogoLooksRare,
    LogoGem,
  }
}
</script>
  
<style lang="scss" scoped src="../assets/scss/main-footer-right.scss"></style>
  