<template>
  <div id="wrapper">
    <main-header ref="header" @walletDisconnected="resetApplicationState" @updateAccount="updateAccount"></main-header>
    <SearchBar :searchTerm="searchTerm" :account="account" @updateSearchTerm="updateSearchTerm" @updateDynos="updateDynos"
      @toggle-grid-size="setGridSize" />
    <MainContent 
      :gridSize="gridSize" 
      :searchTerm="searchTerm" 
      :account="account" 
      :dynos="dynos" 
      @updateSearchTerm="updateSearchTerm" 
      @requestOwner="ownerId = $refs.header.$refs.headerRight.fetchOwner($event)" 
      :ownerId="ownerId" 
    />
    <main-footer></main-footer>
  </div>
</template>

<script>
import MainHeader from './components/MainHeader.vue'
import MainContent from './components/MainContent.vue'
import MainFooter from './components/MainFooter.vue'
import SearchBar from './components/SearchBar.vue';

export default {
  components: {
    MainHeader,
    SearchBar,
    MainContent,
    MainFooter
  },
  data() {
    return {
      searchTerm: '',
      gridSize: 'lg-grid',
      account: '',
      dynos: null,
      ownerId: '',
    };
  },
  methods: {
    updateSearchTerm(newSearchTerm) {
      this.searchTerm = newSearchTerm;
    },
    updateDynos(newDynos) {
      this.dynos = newDynos;
    },
    setGridSize(size) {
      this.gridSize = size;
    },
    updateAccount(newAccount) {
      this.account = newAccount;
    },
    resetApplicationState() {
      this.account = '';
      this.searchTerm = '';
      this.dynos = null;
      this.$emit('resetSearchBar');
    },
  },
}
</script>

<style lang="scss" scoped src="./assets/scss/app.scss"></style>
