<template>
  <div class="main-header">
    <main-header-left />
    <main-header-right 
      ref="headerRight" 
      @walletDisconnected="$emit('walletDisconnected')" 
      @updateAccount="$emit('updateAccount', $event)"
    />
  </div>
</template>

<script>
import MainHeaderLeft from './MainHeaderLeft.vue'
import MainHeaderRight from './MainHeaderRight.vue'

export default {
  components: {
    MainHeaderLeft,
    MainHeaderRight
  }
}
</script>

<style lang="scss" scoped src="../assets/scss/main-header.scss"></style>