<template>
    <svg viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.54151 0.333767C2.76809 0.332457 2.99269 0.375884 3.20245 0.461559C3.41221 0.547234 3.60299 0.673474 3.76385 0.833035L14.0935 11.1627C14.4141 11.4852 14.5941 11.9216 14.5941 12.3764C14.5941 12.8312 14.4141 13.2676 14.0935 13.5901L3.76385 23.9198C3.43451 24.2018 3.01086 24.3492 2.57757 24.3325C2.14429 24.3157 1.73327 24.1361 1.42666 23.8295C1.12006 23.5229 0.940436 23.1119 0.9237 22.6786C0.906964 22.2453 1.05434 21.8216 1.33639 21.4923L10.4437 12.385L1.33639 3.27771C1.09464 3.03794 0.929416 2.73187 0.86161 2.3982C0.793804 2.06453 0.826463 1.71825 0.955455 1.40314C1.08445 1.08803 1.30398 0.818244 1.5863 0.627898C1.86861 0.437553 2.20103 0.335195 2.54151 0.333767Z" fill="white"/>
    </svg>
  </template>
  
  <script>
  export default {
    name: 'ArrowRightIcon'
  }
  </script>
  