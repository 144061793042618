<template>
  <div class="modal" v-if="showModal" @click="closeModal">
    <button class="close" @click="closeModal"><CloseIcon /></button>
    <div class="modal-content" @click.stop>
      <div class="image-container">
        <img v-lazy="localImage.fullSrc[localImage.selectedImageIndex]" :alt="`Image ${localImage.index}`">
        <button class="download-button" @click.stop="downloadImage">
          <DownloadIcon :src="localImage.fullSrc[localImage.selectedImageIndex]" />
        </button>
        <button class="arrow left" v-if="localImage.fullSrc.length > 1" @click="switchImage('previous')"><ArrowLeftIcon /></button>
        <button class="arrow right" v-if="localImage.fullSrc.length > 1" @click="switchImage('next')"><ArrowRightIcon /></button>
      </div>
      <div class="image-info">
        <h2>{{ localImage.data.name }}</h2>
        <!--<p>Owned by: {{ ownerId }}</p> -->
        <div v-if="localImage.data.attributes" class="traits-grid trait-wrapper">
          <div v-for="(attribute, index) in localImage.data.attributes" :key="index" class="trait" @click.stop="filterByTrait(attribute)">
            <div class="trait-type">{{ attribute.trait_type }}</div>
            <div class="trait-value">{{ attribute.value }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowLeftIcon from './ArrowLeftIcon.vue';
import ArrowRightIcon from './ArrowRightIcon.vue';
import CloseIcon from './CloseIcon.vue';
import DownloadIcon from './DownloadIcon.vue';

export default {
  name: 'ImageModal',
  components: {
    ArrowLeftIcon,
    ArrowRightIcon,
    CloseIcon,
    DownloadIcon,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    currentImage: {
      type: Object,
      default: () => ({})
    },
    ownerId: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      localImage: JSON.parse(JSON.stringify(this.currentImage))  // Make a deep copy of the prop
    };
  },
  watch: {
    // When the prop changes, also update the local copy
    currentImage: {
      handler(newValue) {
        this.localImage = JSON.parse(JSON.stringify(newValue));
      },
      deep: true
    },
    showModal: {
      immediate: true,
      handler(isOpen) {
        if (isOpen && this.currentImage) {
          this.$emit('requestOwner', this.currentImage.tokenId);
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    switchImage(direction) {
      this.$emit('switchModalImage', this.localImage, direction);  // Emit event to the parent to switch the image
    },
    downloadImage() {
      const url = this.localImage.fullSrc[this.localImage.selectedImageIndex];
      window.open(url, '_blank');
    },
    filterByTrait(attribute) {
      const newSearchTerm = `${attribute.trait_type}: ${attribute.value}`;
      this.$emit('updateSearchTerm', newSearchTerm);  // Emit the new search term
      this.closeModal();  // Close the modal
    }
  }
};
</script>

<style lang="scss" scoped src="../assets/scss/image-modal.scss"></style>
