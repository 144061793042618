<template>
  <div class="modal">
  <modal-wrapper>
    <button @click="$emit('close')">Close</button>
      <h1>About Dyno Friends</h1>
      <p>Text goes here.</p>
    </modal-wrapper>
  </div>
</template>

<script>
export default {
  name: 'ModalAbout'
}
</script>

<style lang="scss" scoped src="../assets/scss/modal-about.scss"></style>