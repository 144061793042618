<template>
    <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.4919 8.25396H4.49194C3.89194 8.25396 3.49194 7.85396 3.49194 7.25396C3.49194 6.65396 3.89194 6.25396 4.49194 6.25396H16.4919C17.0919 6.25396 17.4919 6.65396 17.4919 7.25396C17.4919 7.85396 17.0919 8.25396 16.4919 8.25396Z"
            fill="#808080" />
        <path
            d="M13.4921 14.254H7.49213C6.89213 14.254 6.49213 13.854 6.49213 13.254C6.49213 12.654 6.89213 12.254 7.49213 12.254H13.4921C14.0921 12.254 14.4921 12.654 14.4921 13.254C14.4921 13.854 14.0921 14.254 13.4921 14.254Z"
            fill="#808080" />
        <path
            d="M19.4922 2.25397H1.49219C0.892187 2.25397 0.492188 1.85397 0.492188 1.25397C0.492188 0.653967 0.892187 0.253967 1.49219 0.253967H19.4922C20.0922 0.253967 20.4922 0.653967 20.4922 1.25397C20.4922 1.85397 20.0922 2.25397 19.4922 2.25397Z"
            fill="#808080" />
    </svg>
</template>
  
<script>
export default {
    name: 'FilterIcon'
}
</script>