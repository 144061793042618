
<template>
    <svg width="145" height="125" viewBox="0 0 145 125" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 77.4C3.9 73 8.3 71.4 12.9 73.9C15.1 75.1 17.4 77.9 17.8 80.4C18.3 83.4 13 87.5 9.5 87.4C4.7 87.3 1.5 85 0 80.4C0 79.4 0 78.4 0 77.4Z"
            fill="var(--primary-color)" />
        <path
            d="M68.8 104.2C65.8 104 62.9 103.7 59.9 103.6C34.6 103.2 22.9 72.6 29.7 56C30.5 54 31.1 51.9 31.8 49.9C40 27.8 58.8 17.9 81.5 24.8C90.2 27.5 98.7 31.7 106.5 36.4C119.7 44.4 121.5 58.6 118.5 71.1C115.1 85.6 106 97.9 89.9 101.8C83 103.4 75.9 103.6 68.8 104.5C68.8 104.5 68.8 104.4 68.8 104.2ZM69.2 90.2C75.2 89.3 81.3 88.6 87.2 87.5C95.5 86 103.3 76.9 104.4 67.8C105.9 55.6 102.6 48.6 93.1 43.9C83.6 39.3 73.4 38.5 63.1 39.7C60.1 40 56.4 41.7 54.5 44C50.4 48.8 46.7 54.2 43.8 59.8C40.8 65.8 41.1 72.4 44.3 78.4C49.6 88.6 58.7 90.7 69.2 90.2Z"
            fill="var(--primary-color)" />
        <path
            d="M27.7 34.5C27.6 38.8 23.9 42.6 19.9 42.5C15.6 42.4 11.9 38.8 11.9 34.7C11.8 30.4 14.9 27.6 19.6 27.7C24.3 27.8 27.7 30.6 27.7 34.5Z"
            fill="var(--primary-color)" />
        <path
            d="M106 1.89998C110 1.79998 113.7 5.09998 113.8 8.69998C113.9 12.7 110.4 16.6 106.6 16.8C102.6 17 98 13.2 97.7 9.59998C97.6 5.99998 101.9 1.99998 106 1.89998Z"
            fill="var(--primary-color)" />
        <path
            d="M41.3 108.8C46 108.9 50.6 112.4 50.7 115.9C50.8 119.8 47 123.4 43 123.3C38.3 123.2 34.4 119.3 34.4 114.8C34.5 111 37 108.8 41.3 108.8Z"
            fill="var(--primary-color)" />
        <path
            d="M89 116.9C89 113 91.9 110.2 95.9 110.1C100.7 110 105.8 113.5 104.6 116.7C103.4 119.8 100.7 123.8 97.9 124.5C93.1 125.6 88.9 121.2 89 116.9Z"
            fill="var(--primary-color)" />
        <path
            d="M135.8 40.6C140.6 40.7 144.4 43.9 144.3 47.7C144.2 51.6 140.7 55.7 137.5 55.6C132.9 55.5 128.7 51.1 128.7 46.5C128.9 42.8 131.6 40.5 135.8 40.6Z"
        fill="var(--primary-color)" />
    <path
        d="M144.1 92.4C143.8 95.5 141.9 97.9 137.9 98C133.2 98.2 128.5 94.3 128.6 90.2C128.7 86.5 130.4 83.8 134.5 83.6C139.5 83.4 144.1 87.3 144.1 92.4Z"
        fill="var(--primary-color)" />
    <path
        d="M58.4 0.499988C62.5 0.599988 65.6 3.29999 65.4 6.59999C65.1 10.7 61.1 15 57.6 14.9C53.8 14.8 49.8 10.5 49.9 6.59999C49.8 2.79999 53.2 0.399988 58.4 0.499988Z"
        fill="var(--primary-color)" />
    <path
        d="M71.7 73.7C66.4 73.7 61.8 68.5 61.8 62.5C61.7 56.1 66.2 51.5 72.6 51.4C79.2 51.3 85.3 56.9 85.5 63.3C85.6 69.2 79.8 73.6 71.7 73.7Z"
        fill="var(--primary-color)" />
</svg></template>
  
<script>
export default {
    name: 'ToggleThemeIcon'
}
</script>