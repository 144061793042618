<template>
  <div class="image-grid" :class="gridSize" ref="imageGrid">
    <transition-group name="fade">
      <div v-for="(image, index) in filteredImages" :key="index" class="image-container" @click.stop="openImage(image)">
        <img v-lazy="image.src[image.selectedImageIndex]" alt="grid-img"/>
        <!--<div class="spinner" v-if="image.loading[image.selectedImageIndex]"></div>-->
        <div class="image-navigation"
          v-if="image.data.attributes && image.data.attributes.find(attribute => attribute.trait_type === 'Artist Print' && attribute.value === 'Yes')">
          <button class="previous-btn" @click.stop="switchImage(image, 'previous')">
            <ArrowLeftIcon />
          </button>
          <button class="next-btn" @click.stop="switchImage(image, 'next')">
            <ArrowRightIcon />
          </button>
        </div>
        <div class="fullscreen-icon">
          <ExpandIcon />
        </div>
      </div>
    </transition-group>

    <p v-if="noResults" class="no-results">Ummm... what did you do?!? I don't see any dynos. Try a different search.</p>
    <transition name="modal">
      <ImageModal 
        :showModal="showModal" 
        :currentImage="currentImage" 
        :ownerId="ownerId" 
        @close="closeModal"
        @switchModalImage="switchModalImage" 
        @requestOwner="$emit('requestOwner', $event)" 
        @updateSearchTerm="$emit('updateSearchTerm', $event)" 
      />
    </transition>
  </div>
</template>

<script>
import ImageModal from './ImageModal.vue';
import imagesLoaded from 'imagesloaded';
import ArrowLeftIcon from './ArrowLeftIcon.vue';
import ArrowRightIcon from './ArrowRightIcon.vue';
import ExpandIcon from './ExpandIcon.vue';


export default {
  name: 'ImageGrid',
  components: {
    ImageModal,
    ArrowLeftIcon,
    ArrowRightIcon,
    ExpandIcon
  },
  props: ['gridSize', 'searchTerm', 'dynos'],
  data() {
    return {
      images: [],
      fullResImages: [],
      modalImages: [],
      showModal: false,
      currentImage: {},
      currentImageIndex: null,
    };
  },
  computed: {
    filteredImages() {
      let images = this.images;

      if (this.searchTerm) {
        const lowerCaseSearchTerm = this.searchTerm.toLowerCase();
        images = images.filter(image => {
          if (
            image.data &&
            image.data.name &&
            image.data.name.toLowerCase().includes(lowerCaseSearchTerm)
          ) {
            return true;
          }

          if (image.data && image.data.attributes) {
            return image.data.attributes.some(attribute =>
              `${attribute.trait_type}: ${attribute.value}`.toLowerCase() === lowerCaseSearchTerm
            );
          }

          return false;
        });
      }

      if (this.dynos) {
        images = images.filter(image => this.dynos.includes(image.index.toString()));
      }

      return images;
    },
    noResults() {
      return this.filteredImages.length === 0;
    }
  },
  async created() {
    const requireContext = require.context('@/assets/img/dynos/1000', false, /.png$/);
    const requireContextAP = require.context('@/assets/img/dynos/1000-AP', false, /.png$/);
    const requireContextFull = require.context('@/assets/img/dynos/full', false, /.png$/);
    const requireContextFullAP = require.context('@/assets/img/dynos/full-AP', false, /.png$/);

    const keys = requireContext.keys().sort((a, b) => {
      const numA = parseInt(a.match(/(\d+)/)[0]);
      const numB = parseInt(b.match(/(\d+)/)[0]);
      return numA - numB;
    });

    for (const key of keys) {
      const match = key.match(/(\d+)/);
      const index = match ? parseInt(match[0]) : null;
      if (index !== null) {
        const data = require(`@/assets/data/${index}.json`);

        // Initialize src and fullSrc as arrays with the default images
        let src = [requireContext(key)];
        let fullSrc = [requireContextFull(`./${index}.png`)];
        let loading = Array(src.length).fill(true);

        // Try to add the Artist Print images if they exist
        try {
          src.push(requireContextAP(`./${index}.png`));
          fullSrc.push(requireContextFullAP(`./${index}.png`));
        } catch (error) {
          //console.warn(`Artist print version of image ${index} not found.`);
        }

        const selectedImageIndex = src.length > 1 ? 1 : 0;
        const image = { index, src, fullSrc, data, selectedImageIndex, loading };
        this.images.push(image);
        this.fullResImages.push({ ...image });
        this.modalImages.push({ ...image });
      }
    }
  },
  mounted() {
    this.imagesLoaded().then(() => {
      // do something when all images are loaded
    });
  },
  beforeUnmount() {
  },
  methods: {
    openImage(image) {
      const modalImage = this.modalImages.find(img => img.index === image.index);
      if (modalImage) {
        modalImage.selectedImageIndex = (modalImage.src[1] || modalImage.fullSrc[1]) ? 1 : 0;
        this.currentImage = modalImage;
        this.showModal = true;
        document.body.classList.add('noscroll');
      }
    },
    closeModal() {
      this.showModal = false;
      this.currentImage = {};
      document.body.classList.remove('noscroll');
    },
    filterByTrait(attribute) {
      this.$emit('updateSearchTerm', `${attribute.trait_type}: ${attribute.value}`);
    },
    imagesLoaded() {
      return new Promise((resolve) => {
        imagesLoaded(this.$refs.imageGrid, resolve);
      });
    },
    switchImage(image, direction) {
      if (!image.src) {
        console.error("image.src is undefined");
        return;
      }
      const newIndex = (image.selectedImageIndex + (direction === 'next' ? 1 : -1) + image.src.length) % image.src.length;
      const indexInImagesArray = this.images.findIndex((img) => img.index === image.index);
      if (indexInImagesArray !== -1) {
        this.images[indexInImagesArray].selectedImageIndex = newIndex;
      }
    },

    switchModalImage(image, direction) {
      if (!image.src) {
        console.error("image.src is undefined");
        return;
      }
      let newIndex;
      if (direction === 'next') {
        newIndex = (image.selectedImageIndex + 1) % image.src.length;
      } else if (direction === 'previous') {
        newIndex = (image.selectedImageIndex + image.src.length - 1) % image.src.length;
      }
      const indexInModalImagesArray = this.modalImages.findIndex((img) => img.index === image.index);
      if (indexInModalImagesArray !== -1) {
        this.modalImages[indexInModalImagesArray].selectedImageIndex = newIndex;
      }
      this.currentImage.selectedImageIndex = newIndex;
    },
  },
};
</script>

<style lang="scss" scoped src="../assets/scss/image-grid.scss"></style>