<template>
  <div class="search-bar">
    <SearchBarLeft 
      ref="searchBarLeft"
      :searchTerm="searchTerm" 
      :account="account" 
      @updateSearchTerm="$emit('updateSearchTerm', $event)"
      @updateDynos="$emit('updateDynos', $event)" 
      />
    <SearchBarRight 
      @toggle-grid-size="$emit('toggle-grid-size', $event)" 
    />
  </div>
</template>

<script>
import SearchBarLeft from './SearchBarLeft.vue';
import SearchBarRight from './SearchBarRight.vue';

export default {
  name: 'SearchBar',
  components: {
    SearchBarLeft,
    SearchBarRight,
  },
  props: {
    searchTerm: {
      type: String,
      default: ''
    },
    account: {
      type: String,
      default: ''
    }
  },
  methods: {
    resetSearchBar() {
      this.$refs.searchBarLeft.resetLocalState();
    }
  }
};
</script>

<style lang="scss" scoped src="../assets/scss/search-bar.scss"></style>
