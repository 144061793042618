<template>
    <svg viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0526 24C11.826 24.0013 11.6014 23.9579 11.3917 23.8722C11.1819 23.7865 10.9911 23.6603 10.8303 23.5007L0.50063 13.1711C0.179979 12.8485 0 12.4122 0 11.9573C0 11.5025 0.179979 11.0662 0.50063 10.7436L10.8303 0.413972C11.1596 0.131928 11.5833 -0.0154521 12.0165 0.00128394C12.4498 0.0180199 12.8608 0.197638 13.1675 0.504247C13.4741 0.810856 13.6537 1.22187 13.6704 1.65516C13.6872 2.08844 13.5398 2.51209 13.2577 2.84144L4.15043 11.9487L13.2577 21.056C13.4995 21.2958 13.6647 21.6019 13.7325 21.9355C13.8003 22.2692 13.7677 22.6155 13.6387 22.9306C13.5097 23.2457 13.2901 23.5155 13.0078 23.7058C12.7255 23.8962 12.3931 23.9985 12.0526 24Z" fill="white"/>
    </svg>
  </template>
  
  <script>
  export default {
    name: 'ArrowLeftIcon'
  }
  </script>
  