<template>
    <svg class="icon" width="31" height="26" viewBox="0 0 31 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M30.8164 3.54848C29.7008 4.05041 28.512 4.3831 27.2727 4.54464C28.5477 3.76387 29.5208 2.53694 29.9783 1.0581C28.7895 1.78502 27.477 2.29848 26.0783 2.58502C24.9495 1.35233 23.3408 0.588867 21.5858 0.588867C18.1808 0.588867 15.4395 3.42348 15.4395 6.89848C15.4395 7.39848 15.4808 7.87925 15.582 8.33694C10.4689 8.08117 5.94453 5.56771 2.90516 1.73887C2.37453 2.6831 2.06328 3.76387 2.06328 4.92733C2.06328 7.11194 3.16016 9.04848 4.79516 10.1696C3.80703 10.1504 2.83766 9.85618 2.01641 9.39271C2.01641 9.41194 2.01641 9.43694 2.01641 9.46194C2.01641 12.5273 4.14828 15.0735 6.94391 15.66C6.44328 15.8004 5.89766 15.8677 5.33141 15.8677C4.93766 15.8677 4.54016 15.8446 4.16703 15.76C4.96391 18.2581 7.22516 20.0946 9.91391 20.1543C7.82141 21.8331 5.16453 22.8446 2.28828 22.8446C1.78391 22.8446 1.30016 22.8216 0.816406 22.7581C3.54078 24.56 6.76953 25.5889 10.2514 25.5889C21.5689 25.5889 27.7564 15.9735 27.7564 7.63887C27.7564 7.36002 27.747 7.09079 27.7339 6.82348C28.9545 5.93502 29.9802 4.82541 30.8164 3.54848Z"
            fill="#808080" />
    </svg>
</template>

<script>
export default {
    name: "LogoTwitter",
};
</script>
