<template>
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="m395.61 0h-279.23a116.52 116.52 0 0 0 -116.38 116.39v279.23a116.51 116.51 0 0 0 116.38 116.38h279.23a116.52 116.52 0 0 0 116.39-116.38v-279.23a116.52 116.52 0 0 0 -116.39-116.39zm68.39 395.62a68.48 68.48 0 0 1 -68.4 68.4h-279.22a68.48 68.48 0 0 1 -68.38-68.4v-279.23a68.47 68.47 0 0 1 68.38-68.39h279.23a68.47 68.47 0 0 1 68.4 68.4z" fill="white"/>
        <path d="m181.84 364.13h52.7c13.45 0 24.78 11 24.52 24.45a24 24 0 0 1 -24 23.53h-110.53a24.66 24.66 0 0 1 -24.67-24.67v-110.5a24 24 0 0 1 24-24 24 24 0 0 1 24 24v53.24l182.3-182.3h-52.7c-13.45 0-24.78-11-24.52-24.46a24 24 0 0 1 24-23.53h110.53a24.67 24.67 0 0 1 24.67 24.67v110.5a24 24 0 0 1 -24 24 24 24 0 0 1 -24-24v-53.24z" fill="white"/>
      </g>
    </svg>
  </template>
  
  <script>
  export default {
    name: 'ExpandIcon'
  }
  </script>